/* Set box-sizing: border-box on all elements* */
html {
	box-sizing: border-box;
  	font-size: 16px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
	margin: 0;
	padding: 0;
    /*scrollbar-width: thin;*/
    /*scrollbar-color: blue orange;*/
}

/* Necessary to keep HUD animations from creating scrollbars */

body, #id {
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	line-height: 1.5;
	/*color: #C1C9D2;*/
  	background-color: #3e4354;
    height: 100vh;
    width: 100vw;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.all {
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6a7485;
  opacity: 1; /* Firefox */
}

input:-webkit-autofill {
    background-color: #FFFFFF0A !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #6a7485;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #6a7485;
}

/* Scrollbars */
/* adapted from https://www.digitalocean.com/community/tutorials/css-scrollbars */
/** {*/
/*	scrollbar-width: thin;*/
/*	scrollbar-color: #3e4354 #29323C;*/
/*}*/

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #29323C;
  	border-radius: 0;
}

*::-webkit-scrollbar-corner {
	background: #3e4354;
  	border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
	background-color: #3e4354;
	border-radius: 0;
	width: 10px;
	border: 1px solid #29323C;
}

.geospatial-viz-panel::-webkit-scrollbar {
  display: none;
}

.invisible-scroll::-webkit-scrollbar {
  display: none;
}

/**::-webkit-scrollbar-track-piece {}*/

/*Element display state pseudo-classes
These pseudo-classes enable the selection of elements based on their display states.

:fullscreen
Matches an element that is currently in fullscreen mode.

:modal
Matches an element that is in a state in which it excludes all interaction with elements outside it until the interaction has been dismissed.

:picture-in-picture
Matches an element that is currently in picture-in-picture mode.

Input pseudo-classes
These pseudo-classes relate to form elements, and enable selecting elements based on HTML attributes and the state that the field is in before and after interaction.

:autofill
Matches when an <input> has been autofilled by the browser.

:enabled
Represents a user interface element that is in an enabled state.

:disabled
Represents a user interface element that is in a disabled state.

:read-only
Represents any element that cannot be changed by the user.

:read-write
Represents any element that is user-editable.

:placeholder-shown
Matches an input element that is displaying placeholder text. For example, it will match the placeholder attribute in the <input> and <textarea> elements.

:default
Matches one or more UI elements that are the default among a set of elements.

:checked
Matches when elements such as checkboxes and radio buttons are toggled on.

:indeterminate
Matches UI elements when they are in an indeterminate state.

:blank
Matches a user-input element which is empty, containing an empty string or other null input.

:valid
Matches an element with valid contents. For example, an input element with the type 'email' that contains a validly formed email address or an empty value if the control is not required.

:invalid
Matches an element with invalid contents. For example, an input element with type 'email' with a name entered.

:in-range
Applies to elements with range limitations. For example, a slider control when the selected value is in the allowed range.

:out-of-range
Applies to elements with range limitations. For example, a slider control when the selected value is outside the allowed range.

:required
Matches when a form element is required.

:optional
Matches when a form element is optional.

:user-invalid
Represents an element with incorrect input, but only when the user has interacted with it.


Location pseudo-classes
These pseudo-classes relate to links, and to targeted elements within the current document.

:any-link
Matches an element if the element would match either :link or :visited.

:link
Matches links that have not yet been visited.

:visited
Matches links that have been visited.

:local-link
Matches links whose absolute URL is the same as the target URL. For example, anchor links to the same page.

:target
Matches the element which is the target of the document URL.

:target-within
Matches elements which are the target of the document URL, but also elements which have a descendant which is the target of the document URL.

:scope
Represents elements that are a reference point for selectors to match against.

Resource state pseudo-classes
These pseudo-classes apply to media that is capable of being in a state where it would be described as playing, such as a video.

:playing
Represents a media element that is capable of playing when that element is playing.

:paused
Represents a media element that is capable of playing when that element is paused.

/*Time-dimensional pseudo-classes
These pseudo-classes apply when viewing something which has timing, such as a WebVTT caption track.

:current
Represents the element or ancestor of the element that is being displayed.

:past
Represents an element that occurs entirely before the :current element.

:future
Represents an element that occurs entirely after the :current element.


Tree-structural pseudo-classes
These pseudo-classes relate to the location of an element within the document tree.

:root
Represents an element that is the root of the document. In HTML this is usually the <html> element.

:empty
Represents an element with no children other than white-space characters.

:nth-child
Uses An+B notation to select elements from a list of sibling elements.

:nth-last-child
Uses An+B notation to select elements from a list of sibling elements, counting backwards from the end of the list.

:first-child
Matches an element that is the first of its siblings.

:last-child
Matches an element that is the last of its siblings.

:only-child
Matches an element that has no siblings. For example, a list item with no other list items in that list.

:nth-of-type
Uses An+B notation to select elements from a list of sibling elements that match a certain type from a list of sibling elements.

:nth-last-of-type
Uses An+B notation to select elements from a list of sibling elements that match a certain type from a list of sibling elements counting backwards from the end of the list.

:first-of-type
Matches an element that is the first of its siblings, and also matches a certain type selector.

:last-of-type
Matches an element that is the last of its siblings, and also matches a certain type selector.

:only-of-type
Matches an element that has no siblings of the chosen type selector.

User action pseudo-classes
These pseudo-classes require some interaction by the user in order for them to apply, such as holding a mouse pointer over an element.

:hover
Matches when a user designates an item with a pointing device, such as holding the mouse pointer over the item.

:active
Matches when an item is being activated by the user. For example, when the item is clicked on.

:focus
Matches when an element has focus.

:focus-visible
Matches when an element has focus and the user agent identifies that the element should be visibly focused.

:focus-within
Matches an element to which :focus applies, plus any element that has a descendant to which :focus applies.

Alphabetical index
Pseudo-classes defined by a set of CSS specifications include the following:

A

:active
:any-link
:autofill
B

:blank Experimental
C

:checked
:current Experimental
D

:default
:defined
:dir() Experimental
:disabled
E

:empty
:enabled
F

:first
:first-child
:first-of-type
:fullscreen
:future Experimental
:focus
:focus-visible
:focus-within
H

:has() Experimental
:host
:host()
:host-context() Experimental
:hover
I

:indeterminate
:in-range
:invalid
:is()
L

:lang()
:last-child
:last-of-type
:left
:link
:local-link Experimental
M

:modal
N

:not()
:nth-child()
:nth-col() Experimental
:nth-last-child()
:nth-last-col() Experimental
:nth-last-of-type()
:nth-of-type()
O

:only-child
:only-of-type
:optional
:out-of-range
P

:past Experimental
:picture-in-picture
:placeholder-shown
:paused
:playing
R

:read-only
:read-write
:required
:right
:root
S

:scope
:state() Experimental
T

:target
:target-within Experimental
U

:user-invalid Experimental
V

:valid
:visited
W

:where()

 */

input[type=range] {
  height: 25px;
  background-color: transparent;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #60b3a1;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #60b3a1;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #60b3a1;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #60b3a1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #60b3a1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #60b3a1;
}
input[type=range]:focus::-ms-fill-upper {
  background: #60b3a1;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.bar-chart-axis,
.scatter-axis,
[class*="histo-y-axis"],
[class*="histo-x-axis"],
[class*="event-line-y-axis"],
[class*="event-line-x-axis"] {
  color: #6a7485;
}

