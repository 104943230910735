/* @import './normalize.css'; */
@import './styles.css';
/* @import-normalize; */
@import './mapbox-overrides.css';


.map-container {
    height: 10rem;
    width: 10rem;
    border: 4px solid #4f566b;
    border-radius: .5rem;
}


.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    margin: 12px;
    border-radius: 4px;
}
