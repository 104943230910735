/* Turns off/hides some base styling on Mapbox Popup component*/
.mapboxgl-popup-content {
	padding: 8px 0 0;
	background-color: transparent;
}

.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup-tip {
	display: none;
}

.mapboxgl-ctrl, .mapboxgl-ctrl-group {
	margin-right: 7px !important;
	background: none;
}

.mapboxgl-ctrl-attrib-inner, .mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-ctrl-group {
	position: fixed;
	top: 40vh;
	right: 2px;
	height: 100%;
	pointer-events: none;
}

.mapboxgl-ctrl-group button {
	border-radius: 4px;
	width: 32px;
	height: 32px;
	box-shadow: none;
	pointer-events: all;
}

.mapboxgl-ctrl-group button+button {
	border-top: none;
	margin-top: 2px;
		pointer-events: all;
}


.mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out, .mapboxgl-ctrl-compass, .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right  {
	z-index: 1000;
}

.mapboxgl-ctrl-icon {
	background-color: #3e4354;
}

.mapboxgl-ctrl-group:not(:empty) {
	box-shadow: none;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22%23c1c9d2%22%3E%20%3Cpath%20d%3D%22M14.5%208.5c-.75%200-1.5.75-1.5%201.5v3h-3c-.75%200-1.5.75-1.5%201.5S9.25%2016%2010%2016h3v3c0%20.75.75%201.5%201.5%201.5S16%2019.75%2016%2019v-3h3c.75%200%201.5-.75%201.5-1.5S19.75%2013%2019%2013h-3v-3c0-.75-.75-1.5-1.5-1.5z%22/%3E%20%3C/svg%3E);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
	border-radius: 0;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22%23c1c9d2%22%3E%20%3Cpath%20d%3D%22M10%2013c-.75%200-1.5.75-1.5%201.5S9.25%2016%2010%2016h9c.75%200%201.5-.75%201.5-1.5S19.75%2013%2019%2013h-9z%22/%3E%20%3C/svg%3E);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22%23333%22%3E%20%3Cpath%20d%3D%22M10.5%2014l4-8%204%208h-8z%22%20fill%3D%22%23c1c9d2%22/%3E%20%3Cpath%20id%3D%22south%22%20d%3D%22M10.5%2016l4%208%204-8h-8z%22%20fill%3D%22%23c1c9d2%22/%3E%20%3C/svg%3E);
}

